.page-applicant {
	height: 100%;

	.applicant {
		height: 100%;

		&__container {
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			padding: 10px 20px;

			@include max-width($scr-xs) {
				padding: 10px 15px;
			}
		}

		&__title {
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__box {
			border-radius: 8px;
			background-color: $c-white;
		}

		&__wrapper {
			padding: 10px;
		}

		&__box-body {
			width: 100%;
			padding: 0;
			gap: 20px 35px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-mobile-input,
			.app-select-input,
			.app-button-input,
			.app-masking-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}
		}

		&__button-container {
			width: 100%;
			padding: 20px;
			display: flex;
			justify-content: flex-end;

			.app-button {
				//
				&--outline {
					//
					.app-button {
						//
						&__label {
							color: $c-teal;
						}
					}
				}

				&:disabled {
					&:first-child {
						border: 0;
						background-color: transparent;

						.app-button__label {
							color: #828a8f;
						}
					}
				}
			}
		}

		&__container-body {
			height: 100%;
			border-radius: 8px;
			background-color: $c-white;
		}

		&__container-box {
			height: 100%;
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			padding: 40px 20px;

			@include max-width($scr-xs) {
				padding: 15px;
			}
		}
	}
}
