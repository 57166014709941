.page-quotation {
	width: 100%;

	.quotation {
		padding: 10px 15px;

		&__title {
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__form {
			width: 100%;
			max-width: 1024px;
			margin: auto;
		}

		&__container {
			width: 100%;
			border-radius: 8px;
			gap: 20px;
			display: flex;
			flex-wrap: wrap;
		}

		&__wrapper {
			width: 100%;
			max-width: 330px;
			gap: 20px;
			display: flex;
			flex-direction: column;

			&:last-child {
				max-width: calc(100% - 350px);
			}

			@include max-width($scr-sm) {
				max-width: 100% !important;
			}
		}

		.card-sum-insured {
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			background-color: $c-white;
			border-radius: 8px;

			&__header {
				margin: 0;
				padding: 20px;
				color: $c-white;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
				background-color: $c-turquoise;
				border-radius: 8px 8px 0 0;
			}

			&__container {
				margin: auto;
				padding: 20px;

				.app-input__label {
					font-size: $text-size-xs;
				}

				.MuiInputBase-input {
					color: $c-black;
					font-size: $text-size-s;
					font-weight: $text-weight-regular;
				}
			}

			&__form {
				gap: 20px;
				display: flex;
				flex-direction: column;
			}

			&__wrapper {
				margin: 0 0 20px 0;
				display: flex;
				justify-content: space-between;
			}

			&__box {
				display: flex;
				justify-content: space-between;
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__description {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			&__button-wrapper {
				cursor: pointer;
				display: flex;

				.app-button {
					max-width: 168px;
					border: 0;

					&--outline {
						padding: 0 5px 0 0;
					}

					&__label {
						color: $c-dark-grey;
						font-size: $text-size-xs;
					}
				}
			}

			&__header-wrapper {
				display: flex;
			}

			&__text {
				color: $c-dark-grey;
				font-size: $text-size-s;

				&:last-child {
					&:before {
						margin: 0 10px;
						content: "|";
						color: $c-black;
					}
				}

				&--active {
					color: $c-black;
				}
			}
		}

		.card-insurer {
			width: 100%;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			background-color: $c-white;
			border-radius: 8px;

			&__wrapper {
				display: flex;
				flex-wrap: wrap;
			}
			&__error-wrapper {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}

			&__body {
				padding: 120px 0;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}

			&__title {
				margin: 30px 0 0 0;
				color: $c-primary;
				text-align: center;
				font-size: $text-size-l;
				font-weight: $text-weight-semibold;
			}

			&__subtitle {
				margin: 0;
				color: $c-dark-grey;
				text-align: center;
				font-size: $text-size-xs;
				font-weight: $text-weight-regular;
			}

			&__description {
				margin: 30px 0 0 0;
				color: $c-black;
				text-align: center;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__sub-description {
				margin: 10px 0 0 0;
				color: $c-primary;
				text-align: center;
				font-size: $text-size-xs;
				font-weight: $text-weight-regular;
			}

			&__clock-icon {
				width: 100px;
				height: 100px;
			}

			&__empty-box-icon {
				width: 166px;
				height: 120px;
			}
		}

		.card-coverage {
			width: 100%;

			&__wrapper {
				padding: 10px;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
			}
		}

		.card-benefits {
			width: 100%;

			&__wrapper {
				padding: 10px;
				display: flex;
				flex-wrap: wrap;
			}

			&__text {
				padding: 0 0 0 80px;
				color: $c-light-grey;
				font-size: $text-size-xs;
			}

			&__alert-text {
				padding: 0 0 0 80px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			&__item {
				padding: 12px 0;
				margin: 0 10px;
				flex: 0 1 calc(50% - 20px);

				@include max-width($scr-md) {
					flex: 0 1 100%;
				}

				&--disabled {
					.checkbox__label {
						margin: 0;
						font-size: $text-size-xs !important;
					}
				}

				.app-checkbox {
					//
					.checkbox {
						//
						&__label {
							margin: 0;
							font-size: $text-size-xs !important;
						}
					}

					.checkbox--active {
						//
						&__label {
							margin: 0;
							font-size: $text-size-xs !important;
						}
					}

					.checkbox--disabled {
						color: $c-light-grey;

						&__label {
							margin: 0;
							font-size: $text-size-xs !important;
						}
					}

					.checkbox__logo {
						width: 24px;
					}
				}

				.app-select-input {
					width: 75%;
					padding: 0 0 0 80px;

					@include max-width($scr-xlg) {
						width: 100%;
					}

					&--disabled {
						background-color: transparent;

						.MuiFormControl-root {
							//
							.MuiSelect-select {
								color: $c-light-grey;
							}
						}
					}

					.MuiFormControl-root {
						//
						.MuiSelect-select {
							font-weight: $text-weight-regular;
							font-size: $text-size-s;
						}
					}
				}

				.app-input {
					width: 75%;
					padding: 0 0 0 80px;

					@include max-width($scr-xlg) {
						width: 100%;
					}

					.MuiFormControl-root {
						//
						.MuiInputBase-root {
							font-weight: $text-weight-regular;
							font-size: $text-size-s;
						}
					}
				}
			}
		}

		.card-additional-driver {
			padding: 10px;
			display: flex;
			flex-direction: column;
			gap: 20px 0;

			.driver-main {
				//
				&__box {
					padding: 15px;
					border-radius: 8px;
					background-color: $c-white;
					box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
					flex: 1;
					display: flex;
					flex-direction: row;
				}

				&__header-input {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					gap: 20px 35px;

					.app-input,
					.app-button-input,
					.app-select-input,
					.app-mobile-input,
					.app-select-status,
					.app-calendar-input,
					.app-masking-input {
						@include min-width($scr-sm) {
							width: calc(50% - 17.5px);
						}
					}
				}

				&__body-input {
					display: none;
				}
			}

			&__note {
				width: 100%;
				margin: 5px 0;
				padding-bottom: 10px;
				text-align: center;
				color: $c-light-grey;
				font-size: $text-size-xs;
				display: block;
			}

			&__error {
				margin: 15px 0 0;
				color: $c-red;
				text-align: center;
				font-size: $text-size-xs;
			}

			&__footer {
				width: 100%;
			}

			&__button {
				border-radius: 8px;
				background-color: $c-white;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
				cursor: pointer;

				.app-button {
					padding: 20px 0 0;
					max-width: 100%;
					background-color: transparent;
					border: 0;

					.app-icon {
						margin: 0 10px 0 0;
					}

					&__label {
						font-size: $text-size-xs;
						color: $c-teal;
					}

					&:disabled {
						.app-icon {
							opacity: 0.5;
							filter: grayscale(1);
						}

						.app-button__label {
							color: $c-light-grey;
						}
					}
				}
			}
		}

		.card-total-quotation {
			width: 100%;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			background-color: $c-white;
			border-radius: 8px;

			.total-quotation {
				padding: 20px;
				display: flex;
				justify-content: space-between;

				&__wrapper {
					display: flex;
					flex-direction: column;
				}

				&__title {
					margin: 0;
					color: $c-black;
					font-size: $text-size-body;
					font-weight: $text-weight-semibold;
				}

				&__price {
					margin: 0;
					color: $c-black;
					font-size: $text-size-m;
					font-weight: $text-weight-semibold;
					text-align: right;
				}

				&__description {
					margin: 0;
					color: $c-dark-grey;
					font-size: $text-size-s;
					font-weight: $text-weight-regular;
					text-align: right;
				}
			}

			.recalculate-button {
				padding: 10px 0;

				&__wrapper {
					gap: 5px;
					display: flex;
				}

				&__text {
					margin: 5px 0;
					color: $c-red;
					font-size: $text-size-xs;
				}

				.app-button {
					padding: 0;
					width: auto;
					background-color: transparent;
					flex-direction: row-reverse;

					&:disabled {
						border: 0;

						.app-button__label {
							color: $c-light-grey;
						}

						.app-icon {
							filter: grayscale(1);
							animation: spin 4s linear infinite;
						}
					}

					.app-icon {
						width: 18px;
						height: 18px;
						margin: 0 10px;
					}

					&__label {
						color: $c-teal;
					}
				}
			}
		}

		&__description {
			margin: 0;
			padding: 0 20px;
			color: $c-dark-grey;
			font-weight: $text-weight-regular;
			font-size: $text-size-xs;
		}

		&__button-container {
			padding: 20px;
			display: flex;
			justify-content: flex-end;

			.app-button {
				//
				&__label {
					font-size: $text-size-xs;
				}

				&:disabled {
					&:first-child {
						border: 0;
						background-color: transparent;

						.app-button__label {
							color: #828a8f;
						}
					}
				}
			}
		}
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
